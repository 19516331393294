import React from 'react';
import {useSelector} from 'react-redux';

import MarketTile from 'web/components/market_tile';

import {StoreData} from '../../controller';

const segmentFeature = 'market';

export interface SectionProps {
  category: {
    id: string;
    name: string;
    shortName: string;
  };
  productIds: string[];
}

const Section: React.FC<SectionProps> = ({productIds, category}) => {
  const sectionId = `for-you-${category.id}`;
  const {products, quantityByProductId} = useSelector((state: StoreData) => ({
    products: state.products,
    quantityByProductId: state.basketItemsByProductId,
  }));

  return (
    <div>
      <div className="product-listings-view__category-anchor" id={sectionId} />
      <h3 className="for-you__section-header">{category.name}</h3>
      <div className="page">
        <div className="grid-items for-you__product-tile">
          {productIds.map((productId) => {
            const product = products?.[productId];
            const quantityInBasket = quantityByProductId?.[productId]?.quantity ?? 0;
            const position = productIds.indexOf(productId) + 1;
            return (
              <MarketTile
                product={product}
                key={`${sectionId}-${productId}`}
                segmentFeature={segmentFeature}
                quantityInBasket={quantityInBasket}
                position={position}
                context={{categoryName: category.id}}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Section;
