import React from 'react';

import Image from 'web/components/image';

export const BANNER_PATH = '/survey ';

interface CuratedForYouBannerProps {
  hasAnsweredSurvey: boolean;
}

const CuratedForYouBanner: React.FC<CuratedForYouBannerProps> = ({hasAnsweredSurvey}) => {
  return (
    <div className="for-you__banner">
      <Image
        className="for-you-banner__image"
        src="/img/svg/icons/order_package.svg"
        alt="curated for you banner icon"
        width={52}
      />
      <p className="for-you-banner__description">
        Welcome to your personalized page. Here you’ll find products carefully selected based on
        your preferences. You can come back to this aisle in the For You menu at the top right of
        the site.
        {!hasAnsweredSurvey && (
          <>
            &nbsp;Haven’t taken the quiz yet?&nbsp;
            <a href={BANNER_PATH}>You can find it here.</a>
          </>
        )}
      </p>
    </div>
  );
};

export default CuratedForYouBanner;
